<script>
export default {
  components: {},
  data() {
    return {
      showModal: false,
      products: [],
      confirm: null,
      close: null
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    openEventDialog: function (data) {
      this.showModal = true;
      this.products = data;
      return new Promise((resolve, reject) => {
          this.confirm = () => {
            resolve(this.products);
          }
          this.close = () => {
            reject();
          }
        }).finally(() => {
          this.showModal = false;
          setTimeout(() => {
            this.products = [];
          }, 500);
        });
    },
    focusConfirmBtn: function() {
      this.$refs.confirmBtn.focus();
    }
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.completedconfirmation')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="productCompletedModal"
    no-close-on-backdrop
    @shown="focusConfirmBtn"
  >
    <p class="delivered-text">
      {{ $t("general.areyousureconfirmproductcompleted") }}
    </p>
    <p v-for="(product, index) in products" :key="index">{{product.name}}</p>
    <template #modal-footer class="text-right pt-3">
      <b-button variant="light" @click="close">{{
        $t("general.cancel")
      }}</b-button>
      <b-button ref="confirmBtn" variant="primary" @click="confirm">{{
        $t("general.confirm")
      }}</b-button>
    </template>
  </b-modal>
</template>
