<script>
import ProductHelper from "@/utils/productHelper";
import KitItems from "./kitItem";
import ProductItems from "./productItem";
export default {
  components: {
    KitItems,
    ProductItems,
  },
  props: {
    products: Array,
    showCompleted: Boolean,
    toggleKitView: Boolean,
    openAllKitView: Boolean,
    currentStep: {},
  },
  data() {
    return {
      productShowed: [],
      kits: [],
      visibleKitAndProducts: [],
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    openKitProducts(row) {
      row.item.isOpen = true;
    },
    closeKitProducts(row) {
      row.item.isOpen = false;
    },
    completeProduct(item) {
      if (item.isKit) {
        this.$emit(
          "completeProduct",
          item.products.filter(
            (x) =>
              !ProductHelper.isProductStepCompleted(x, this.currentStep) &&
              !(
                ProductHelper.hasNoProductStep(x, this.currentStep) ||
                ProductHelper.isProductDamaged(x) ||
                ProductHelper.isProductBackOrder(x)
              )
          )
        );
      } else {
        this.$emit("completeProduct", [item]);
      }
    },
    isProductStepCompleted(product) {
      return ProductHelper.isProductStepCompleted(product, this.currentStep);
    },
    hasNoProductStep(product) {
      return ProductHelper.hasNoProductStep(product, this.currentStep);
    },
    isProductDamaged(product) {
      return ProductHelper.isProductDamaged(product);
    },
    isProductBackOrder(product) {
      return ProductHelper.isProductBackOrder(product);
    },
    rowClicked(item) {
      if (!item.isKit) {
        this.$emit("product-clicked", item);
      }
    },
    toggleItemDetail(item) {
      if (this.productShowed.includes(item)) {
        this.productShowed.pop(item);
      } else {
        this.productShowed.push(item);
      }
    },
    isShowed(item) {
      return this.productShowed.includes(item);
    },
  },
  computed: {
    tableFields() {
      return [
        {
          key: "isDetached",
          label: this.$t("general.types"),
          thClass: "col-md-1",
          tdClass: "col-md-1 align-middle",
        },
        {
          key: "name",
          label: this.$t("general.names"),
          thClass: "col-md-3",
          tdClass: "col-md-3 align-middle",
        },
        {
          key: "room",
          label: this.$t("general.room"),
          thClass: "col-md-2",
          tdClass: "col-md-2 align-middle",
        },
        {
          key: "kitName",
          label: this.$t("general.kit"),
          thClass: "col-md-2",
          tdClass: "col-md-2 align-middle",
        },
        {
          key: "status",
          label: this.$t("general.status"),
          thClass: "col-md-2",
          tdClass: "col-md-2 align-middle",
        },
        {
          key: "actions",
          label: "",
          thClass: "",
          tdClass: "col-md-2 align-middle",
        },
      ];
    },
    visibleProducts() {
      return (
        this.products?.filter((x) =>
          ProductHelper.isProductVisible(
            x,
            this.currentStep,
            this.showCompleted
          )
        ) ?? []
      );
    },
    visibleKitProducts() {
      var result = [];

      for (var kit in this.kits) {
        if (!kit || kit == "") {
          kit = "nokit";
        }
        result.push(this.kits[kit]);
        if (this.kits[kit].isOpen) {
          var visibleProd = this.kits[kit].products.filter((x) =>
            ProductHelper.isProductVisible(
              x,
              this.currentStep,
              this.showCompleted
            )
          );
          for (let index in visibleProd) {
            result.push(visibleProd[index]);
          }
        }
      }
      return result;
    },
    computedKits() {
      var hash = Object.create(null);
      let noroom = this.$t("general.noroom");
      let currentStep = this.currentStep;
      this.products.forEach(function (o) {
        var key = o.kitName;
        if (!key || key == "") {
          key = "nokit";
        }
        if (!hash[key]) {
          hash[key] = {
            name: key,
            products: [],
            isKit: true,
            isOpen: false,
            nbWarning: 0,
            room: "",
          };
        }
        hash[key].products.push(o);
        if (o.isBackOrder || o.isDamaged) {
          hash[key].nbWarning += 1;
        }
        let roomName = o.room == null ? noroom : o.room;
        if (!hash[key].room.includes(roomName)) {
          if (hash[key].room.length != 0) {
            hash[key].room += ", ";
          }
          hash[key].room += roomName;
        }
        hash[key].completed = !currentStep
          ? true
          : hash[key].products.filter(
              (x) =>
                ProductHelper.isProductStepCompleted(x, currentStep) ||
                !ProductHelper.hasNoProductStep(x, currentStep) ||
                !ProductHelper.isProductDamaged(x) ||
                !ProductHelper.isProductBackOrder(x)
            ).length == 0;
      });
      return hash;
    },
  },
  watch: {
    kits: {
      handler: function (newValue, oldValue) {
        for (var i in newValue) {
          newValue[i].isOpen = oldValue[i]?.isOpen ?? false;
        }
      },
    },
    computedKits: {
      handler: function (newValue) {
        this.kits = { ...newValue };
      },
    },
  },
};
</script>
<template>
  <div class="col">
    <div class="d-md-none">
      <KitItems
        v-model="kits"
        v-if="toggleKitView"
        @product-clicked="rowClicked"
        @product-complete="completeProduct"
        :currentStep="currentStep"
        :showCompleted="showCompleted"
      />
      <ProductItems
        v-model="visibleProducts"
        v-else
        @product-clicked="rowClicked"
        @product-complete="completeProduct"
        :currentStep="currentStep"
        :showCompleted="showCompleted"
      />
    </div>
    <b-table
      :items="toggleKitView ? visibleKitProducts : visibleProducts"
      :fields="tableFields"
      responsive
      ref="productTable"
      @row-clicked="rowClicked"
      class="d-none d-md-block"
    >
      <template #cell(name)="row">
        <span v-if="row.item.name != 'nokit'">{{ row.item.name }}</span>
        <span v-if="row.item.name == 'nokit'" :ref="row.index">{{
          $t("general." + row.item.name)
        }}</span>
      </template>
      <template #cell(isDetached)="row">
        <i
          class="mdi mdi-36px mdi-cube-outline"
          v-if="!row.item.isKit && !row.value"
        ></i>
        <i
          class="mdi mdi-36px mdi-puzzle-outline"
          v-if="!row.item.isKit && row.value"
        ></i>
        <i class="mdi mdi-36px custom-kit" v-if="row.item.isKit"></i>
      </template>
      <template #cell(status)="row">
        <span
          class="badge badge-secondary badge-danger p-2 m-1 m-bo font-size-14"
          v-if="row.item.isBackOrder"
        >
          {{ $t("general.bo") }}
        </span>
        <span
          class="badge badge-secondary badge-warning p-2 m-1 m-bo font-size-14"
          v-if="row.item.isDamaged"
        >
          {{ $t("general.damaged") }}
        </span>
        <span
          class="badge badge-secondary p-2 m-1 m-bo font-size-14"
          v-if="hasNoProductStep(row.item)"
        >
          {{ $t("general.nostep") }}
        </span>
        <div v-if="row.item.nbWarning && row.item.nbWarning != 0">
          <i class="mdi mdi-36px mdi-alert-outline align-middle"></i>
          <span class="p-2">: {{ row.item.nbWarning }}</span>
        </div>
      </template>
      <template #cell(actions)="row">
        <div class="d-flex flex-row justify-content-end">
          <div
            v-if="
              (row.item.isKit && !row.item.completed) ||
              (!row.item.isKit &&
                !(
                  isProductStepCompleted(row.item) ||
                  hasNoProductStep(row.item) ||
                  isProductDamaged(row.item) ||
                  isProductBackOrder(row.item)
                ))
            "
            role="button"
            @click="completeProduct(row.item)"
          >
            <i class="mdi mdi-36px mdi-checkbox-blank-outline mx-2"></i>
          </div>
          <div
            v-if="
              (row.item.isKit && row.item.completed) ||
              (isProductStepCompleted(row.item) &&
                !hasNoProductStep(row.item) &&
                !isProductDamaged(row.item) &&
                !isProductBackOrder(row.item))
            "
          >
            <i
              class="mdi mdi-36px mdi-checkbox-marked-outline text-success mx-2"
            ></i>
          </div>
          <div
            v-if="
              (!row.item.isKit && hasNoProductStep(row.item)) ||
              isProductDamaged(row.item) ||
              isProductBackOrder(row.item)
            "
          >
            <i class="mdi mdi-36px mdi-checkbox-marked-outline mx-2"></i>
          </div>
          <b-button
            v-if="row.item.isKit && !row.item.isOpen"
            @click="openKitProducts(row)"
            class="w-xs my-2"
            ><i class="mdi mdi-18px mdi-chevron-down"></i
          ></b-button>
          <b-button
            v-if="row.item.isKit && row.item.isOpen"
            @click="closeKitProducts(row)"
            class="w-xs my-2"
            ><i class="mdi mdi-18px mdi-chevron-up"></i
          ></b-button>
          <b-button
            size="sm"
            v-show="
              !row.item.isKit &&
              !row._showDetails &&
              (row.item.isBackOrder || row.item.isDamaged)
            "
            @click="row.toggleDetails"
            class="w-xs my-2"
            ><i
              :class="{
                mdi: true,
                'mdi-18px': true,
                'mdi-chevron-down': !row.detailsShowing,
                'mdi-chevron-up': row.detailsShowing,
              }"
            ></i
          ></b-button>
        </div>
      </template>
      <template #row-details="row">
        <div class="row">
          <div class="col-md-8 offset-md-1">
            <div
              v-if="
                row.item.isDamaged &&
                row.item.parts.length ==
                  row.item.parts.filter((x) => x.isDamaged).length
              "
            >
              <i class="mdi mdi-36px ico-damaged text-warning align-middle mr-2"></i>
              {{ $t("general.allparts") }}
            </div>
            <div
              v-if="
                row.item.isBackOrder &&
                row.item.parts.length ==
                  row.item.parts.filter((x) => x.isBackOrder).length
              "
            >
              <span
                class="font-weight-bold font-size-20 text-danger align-middle mr-2"
                >{{$t("general.bo")}}</span
              >
              {{ $t("general.allparts") }}
            </div>
            <div
              v-for="(part, index) in row.item.parts.filter(
                (x) => x.isDamaged || x.isBackOrder
              )"
              :key="index"
              class="m-2"
            >
              <i
                v-if="
                  !(
                    row.item.parts.length ==
                    row.item.parts.filter((x) => x.isDamaged).length
                  ) && part.isDamaged
                "
                class="mdi mdi-24px ico-damaged text-warning align-middle mr-2"
              ></i
              ><span
                v-if="
                  !(
                    row.item.parts.length ==
                    row.item.parts.filter((x) => x.isBackOrder).length
                  ) && part.isBackOrder
                "
                class="font-weight-bold font-size-16 text-danger mr-2"
                >{{$t("general.bo")}}</span
              >
              <span
                class="ml-2"
                v-if="
                  (!(
                    row.item.parts.length ==
                    row.item.parts.filter((x) => x.isDamaged).length
                  ) &&
                    part.isDamaged) ||
                  (!(
                    row.item.parts.length ==
                    row.item.parts.filter((x) => x.isBackOrder).length
                  ) &&
                    part.isBackOrder)
                "
                >{{ part.name }}</span
              >
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>