<template>
    <div>
        <audio id="good_scan">
            <source src="/sounds/Good_Scan.wav" type="audio/wav">
        </audio>
        <audio id="wrong_scan">
            <source src="/sounds/Wrong_Scan.wav" type="audio/wav">
        </audio>
        <audio id="wrong_job">
            <source src="/sounds/Wrong_Job.wav" type="audio/wav">
        </audio>
    </div>
</template>
<script>
export default {
    data() {
        return {
            waitForBarcode: false,
            currentBarCode: "",
            notifyBarCodeScan: false,
            waitForNotifyBarCodeScan: false
        }
    },
    mounted() {
        window.addEventListener("keypress", this.onkeydown);
        window.addEventListener("paste", this.onpaste);
    },
    destroyed() {
        window.removeEventListener("keypress", this.onkeydown);
        window.removeEventListener("paste", this.onpaste);
    },
    methods: {
        onkeydown: function (e) {
            if (!this.waitForBarcode && e.code === 'KeyI') {
                if (this.waitForNotifyBarCodeScan) {
                    this.$bvToast.toast("Scan information " + (this.notifyBarCodeScan ? "deactivated" : "activated"), {
                        title: 'Scan info',
                        appendToast: true,
                        autohide: false,
                        variant: "info",
                        solid: true,
                    });
                    this.notifyBarCodeScan = !this.notifyBarCodeScan;
                } 
                this.waitForNotifyBarCodeScan = !this.waitForNotifyBarCodeScan;
            } else {
                this.waitForNotifyBarCodeScan = false;
                let webcabKeyCode;
                if (e.keyCode) { webcabKeyCode = e.keyCode }
                else { webcabKeyCode = e.which }

                if ((this.$store.state.userconfig.config.typeScan == 0 && (this.waitForBarcode && webcabKeyCode != 13 || this.currentBarCode.length > 0))
                    || (this.$store.state.userconfig.config.typeScan != 0 && (webcabKeyCode != 13 || this.currentBarCode.length > 0))) {
                    if (webcabKeyCode == 13) {
                        this.waitForBarcode = false;
                        this.currentBarCode = this.currentBarCode.toLowerCase();
                        this.$emit("scan", this.currentBarCode);
                        if (this.notifyBarCodeScan) {
                            this.$bvToast.toast('Scan: ' + this.currentBarCode, {
                                title: 'Scan info',
                                appendToast: true,
                                variant: "info",
                                solid: true,
                            });
                        }
                        this.currentBarCode = "";
                    } else {
                        this.currentBarCode += this.mapKeyPressToActualCharacter(e.shiftKey, webcabKeyCode);
                    }
                } else if (webcabKeyCode == 13) {
                    this.waitForBarcode = true;
                }
            }
        },
        onpaste: function (e) {
            if ((this.$store.state.userconfig.config.typeScan == 0 && (this.waitForBarcode || this.currentBarCode.length > 0))
                || (this.$store.state.userconfig.config.typeScan != 0)) {
                this.waitForBarcode = false;
                this.$emit("scan", e.clipboardData.getData('Text/plain').toLowerCase());
                this.currentBarCode = "";
            }
        },
        wrongjob: function () {
            document.getElementById("wrong_job").play();
        },
        wrongscan: function () {
            document.getElementById("wrong_scan").play();
        },
        goodscan: function () {
            document.getElementById("good_scan").play();
        },
        mapKeyPressToActualCharacter: function (isShiftKey, characterCode) {
            if (characterCode === 27 || characterCode === 8 || characterCode === 9 || characterCode === 20 || characterCode === 16 || characterCode === 17 || characterCode === 91 || characterCode === 13 || characterCode === 92 || characterCode === 18) {
                return false;
            }
            if (typeof isShiftKey != "boolean" || typeof characterCode != "number") {
                return false;
            }
            var characterMap = [];
            characterMap[192] = "~";
            characterMap[49] = "!";
            characterMap[50] = "@@";
            characterMap[51] = "#";
            characterMap[52] = "$";
            characterMap[53] = "%";
            characterMap[54] = "^";
            characterMap[55] = "&";
            characterMap[56] = "*";
            characterMap[57] = "(";
            characterMap[48] = ")";
            characterMap[109] = "-";
            characterMap[107] = "+";
            characterMap[219] = "{";
            characterMap[221] = "}";
            characterMap[220] = "|";
            characterMap[59] = ":";
            characterMap[222] = "\"";
            characterMap[187] = "+";
            characterMap[188] = "<";
            characterMap[189] = "-";
            characterMap[190] = ">";
            characterMap[191] = "?";
            characterMap[32] = " ";
            var character = "";
            if (isShiftKey) {
                if (characterCode >= 65 && characterCode <= 90) {
                    character = String.fromCharCode(characterCode);
                } else {
                    character = characterMap[characterCode];
                }
            } else {
                if (characterCode >= 65 && characterCode <= 90) {
                    character = String.fromCharCode(characterCode).toLowerCase();
                } else {
                    if (characterCode > 99) {
                        character = characterMap[characterCode];
                    } else {
                        character = String.fromCharCode(characterCode);
                    }
                }
            }

            if (character == undefined || character == null) {
                character = String.fromCharCode(characterCode);
            }

            return character;
        }
    }
}
</script>