<script>
import ProductItems from "./productItem";
export default {
  components: {
    ProductItems,
  },
  props: ["value", "currentStep", "showCompleted"],
  data() {
    return {};
  },
  created() {},
  beforeDestroy() {},
  methods: {
    rowClicked: function (item) {
      this.$emit("product-clicked", item);
    },
    completeProduct: function (item) {
      this.$emit("product-complete", item);
    }
  },
  computed: {},
  watch: {},
};
</script>
<template>
  <div>
    <template v-for="(item, index) in value">
      <div :key="index">
        <div class="row py-3">
          <div class="col-auto align-items-start">
            <i class="mdi mdi-36px custom-kit"></i>
          </div>
          <div class="col">
            <div class="row justify-content-start align-items-center pt-2">
              <div class="">
                <h4 v-if="item.name != 'nokit'">{{ item.name }}</h4>
                <h4 v-if="item.name == 'nokit'" :ref="index">
                  {{ $t("general." + item.name) }}
                </h4>
                <div v-show="item.room">
                  <b>{{ $t("general.room") }}:</b> {{ item.room }}
                </div>
                <div v-show="item.kitName">
                  <b>{{ $t("general.kit") }}:</b> {{ item.kitName }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto ml-auto">
            <div class="d-flex flex-row justify-content-end">
              <div v-if="item.nbWarning && item.nbWarning != 0">
                <i class="mdi mdi-36px mdi-alert-outline align-middle"></i>
                <span class="p-2">: {{ item.nbWarning }}</span>
              </div>
              <div
                v-if="!item.completed"
                role="button"
                @click="completeProduct(item)"
              >
                <i class="mdi mdi-36px mdi-checkbox-blank-outline mx-2"></i>
              </div>
              <div v-if="item.completed">
                <i
                  class="
                    mdi mdi-36px mdi-checkbox-marked-outline
                    text-success
                    mx-2
                  "
                ></i>
              </div>
              <b-button
                v-if="item.isKit"
                @click="item.isOpen = !item.isOpen"
                class="my-2"
                ><i
                  :class="{
                    mdi: true,
                    'mdi-18px': true,
                    'mdi-chevron-down': !item.isOpen,
                    'mdi-chevron-up': item.isOpen,
                  }"
                ></i
              ></b-button>
            </div>
          </div>
        </div>
        <div class="row" v-if="item.isOpen">
          <div class="col-md-8 offset-1">
            <ProductItems
              v-model="item.products"
              @product-clicked="rowClicked"
              @product-complete="completeProduct"
              :currentStep="currentStep"
              :showCompleted="showCompleted"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>