<script>
import DocumentationTranslationMixin from "@/mixins/documentationTranslationMixin";
export default {
  name: "SignatureModal",
  mixins: [DocumentationTranslationMixin],
  data() {
    return {
      showModal: false,
      documentation_signature_info: null,
      resolveFunction: null,
      rejectFunction: null,
    };
  },
  computed: {
    documentation_options_signature() {
      return {
        folder: {
          name: "signatureName",
          path: "signature",
          sub_name: "signature",
          sub_folder_name: null,
          sub_folder_path: null,
        },
        labels: this.documentationOptionLabels,
      };
    },
    isAuthorized() {
        return this.$documentation.authorization?.isAuthorized;
    }
  },
  methods: {
    openEventDialog(documentation_signature_info) {
      return new Promise((resolve, reject) => {
        this.resolveFunction = resolve;
        this.rejectFunction = reject;
        this.documentation_signature_info = documentation_signature_info;
        this.showModal = true;
      });
    },
    confirm() {
      this.$refs.signature.confirmSignature().then(
        () => {
          this.resolveFunction();
          this.showModal = false;
        },
        (error) => this.rejectFunction(error)
      );
    },
    reset() {
      this.$refs.signature.resetSignature();
    },
    close() {
      this.$refs.signature.resetSignature();
      this.showModal = false;
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    size="xl"
    no-close-on-backdrop
    :title="$t('general.clientsignature')"
  >
    <signature-component
      ref="signature"
      :options="documentation_options_signature"
      v-model="documentation_signature_info"
    />
    <template #modal-footer class="text-right pt-3">
      <b-button variant="warning" @click="reset">{{
        $t("general.reset")
      }}</b-button>
      <b-button variant="" @click="close">{{ $t("general.cancel") }}</b-button>
      <b-button variant="primary" :disabled="!isAuthorized" @click="confirm">{{
        $t("general.confirm")
      }}</b-button>
    </template>
  </b-modal>
</template>