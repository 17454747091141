<script>
import Multiselect from "vue-multiselect";
import AlertPartModal from "@/components/modals/alertPartModal";
import DocumentationTranslationMixin from "@/mixins/documentationTranslationMixin";

export default {
  mixins: [DocumentationTranslationMixin],
  components: {
    AlertPartModal,
    Multiselect,
  },
  data() {
    return {
      showModal: false,
      product: {},
      reasons: [],
      reason: {},
      confirm: null,
      close: null,
      problemNote: "",
      workOrderName: "",
    };
  },
  created() { },
  beforeDestroy() { },
  methods: {
    openAlert: function (data) {
      this.showModal = true;
      this.product = data.product;
      this.reasons = data.reasons;
      this.workOrderName = data.workOrderName;
      this.reason = this.reasons[0];
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          this.showModal = false;
          var parts = this.product.parts.filter((x) => !x.isDamaged);
          if (parts.length == 0) {
            resolve({
              ProblemNote: this.problemNote,
              ReasonIdPublic: this.reason.idPublic,
              IsDamaged: true,
              IsBackOrder: false,
              PartIdPublics: [],
              ProductIdPublic: this.product.idPublic,
            });
          } else {
            this.$refs.alertPartModal.openAlertEventDialog(parts).then(
              (result) => {
                data.product.isDamaged = true;
                resolve({
                  ProblemNote: this.problemNote,
                  ReasonIdPublic: this.reason.idPublic,
                  IsDamaged: true,
                  IsBackOrder: false,
                  PartIdPublics: result,
                  ProductIdPublic: this.product.idPublic,
                });
              },
              (error) => {
                this.close(error);
              }
            );
          }
        };
        this.close = (action) => {
          reject(action);
        };
      }).finally(() => {
        this.showModal = false;
        setTimeout(() => {
          this.product = {};
          this.reasons = [];
          this.reason = this.reasons[0];
          this.problemNote = "";
        }, 500);
      });
    }
  },
  computed: {
    documentation_workorder() {
      return {
        id: this.workOrderName,
        title: this.workOrderName,
        userCreated: false
      }
    },
    documentation_options() {
      return {
        folder: {
          name: this.$t('general.jobsitetracking'), path: 'jobsitetracking', sub_path: 'damaged', sub_name: this.$t('general.damaged')
        },
        labels: this.documentationOptionLabels
      }
    }
  }
};
</script>

<template>
  <div>
    <b-modal v-model="showModal" :title="$t('general.alert')" title-class="text-black font-18" scrollable size="lg"
      body-class="p-3" id="alertModal">
      <p>{{ $t("general.pleasechoosereasonsendoffice") }}</p>
      <div class="form-group">
        <label>{{ $t("general.reason") }} :</label>
        <multiselect style="max-width: 500px; flex-grow: 2" v-model="reason" :show-labels="false" :options="reasons"
          label="name" track-by="idPublic" :allow-empty="false" :multiple="false">
        </multiselect>
      </div>

      <div class="form-group">
        <label for="notes">Notes:</label>
        <textarea class="form-control" placeholder="Notes" name="notes" rows="3" v-model="problemNote">
        </textarea>
      </div>
      <documentation-component ref="documentation_component" :options="documentation_options"
        v-model="documentation_workorder" :is_direct="true" />
      <template #modal-footer class="text-right pt-3">
        <b-button variant="light" @click="close('cancel')">{{
      $t("general.close")
    }}</b-button>
        <b-button variant="light" @click="confirm">{{
      $t("general.confirm")
    }}</b-button>
      </template>
    </b-modal>
    <AlertPartModal ref="alertPartModal" />
  </div>
</template>