<script>
import DocumentationTranslationMixin from "@/mixins/documentationTranslationMixin";
export default {
  mixins: [DocumentationTranslationMixin],
  components: {
  },
  data() {
    return {
      showModal: false,
      workOrder: {},
    };
  },
  created() {

  },
  beforeDestroy() {
  },
  computed: {
    documentation_workorder() {
      return {
        id: this.workOrder.name,
        title: this.workOrder.name,
        userCreated: false
      }
    },
    documentation_options() {
      return {
        folders: [
          {
            name: this.$t('general.plansAndDrawings'), path: 'plans', icon: 'mdi-text-box-multiple-outline'
          }, {
            name: this.$t('general.jobsitetracking'), path: 'jobsitetracking', icon: 'mdi-dots-horizontal-circle-outline'
          }
        ],
        labels: this.documentationOptionLabels
      }
    },
  },
  methods: {
    openEventDialog: function (workOrder) {
      this.showModal = true;
      this.workOrder = workOrder;
    },
    openDocumentationFiles(fileType) {
      this.$refs.documentation_component.$refs.folderModal.openFolder({ ...this.$refs.documentation_component.folders.find(x => x.name === fileType), ...{id:this.workOrder.id, title: this.workOrder.name} });
    },
    closeModal() {
      this.showModal = false;

      //empty lorsque le dialog nest plus visible
      setTimeout(() => {
        this.workOrder = {};
      }, 500)
    },
    confirm() {
      this.showModal = false;
    },
  },
}
</script>
<template>
  <b-modal v-model="showModal" :title="$t('general.files')" body-class="p-3">
    <div class="row">
      <div class="col-12">
        <documentation-component ref="documentation_component" :options="documentation_options" v-model="documentation_workorder" />
      </div>
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button variant="light" @click="closeModal">{{ $t('general.close') }}</b-button>
    </template>
  </b-modal>
</template>