export default {
    isProductStepCompleted(product, currentStep) {
        if(product.isKit || !product.productSteps || !currentStep) {
            return false;
        }
        var currentProductStep = product.productSteps.filter(p => p.stepIdPublic === currentStep.idPublic);
        if(!currentProductStep || currentProductStep.length == 0) {
            return false;
        }
        if(currentProductStep[0].status == "Completed") {
            return true;
        }
        return false;            
    },
    hasNoProductStep(product, currentStep) {
        if(product.isKit) {
            return false;
        }
        if(!currentStep || !product.productSteps) {
            return true;
        }
        var currentProductStep = product.productSteps.filter(x => x.stepIdPublic == currentStep.idPublic);
        return currentProductStep.length == 0;
    },
    isProductDamaged(product) {
        return product.isDamaged && 
        (product.parts.filter(x => !x.isDamaged).length == 0 || (product.parts == null || product.parts.length == 0));
    },
    isProductBackOrder(product) {
        return product.isBackOrder && 
        (product.parts.filter(x => !x.isBackOrder).length == 0 || (product.parts == null || product.parts.length == 0));
    },
    isProductVisible(product, currentStep, showCompleted) {
        if (
            !showCompleted &&
            (this.isProductStepCompleted(product, currentStep) ||
              this.isProductDamaged(product) ||
              this.hasNoProductStep(product, currentStep) ||
              this.isProductBackOrder(product))
          ) {
            return false;
          }
          if (currentStep) {
            var currentProductStep = product.productSteps.filter(
              (p) => p.stepIdPublic === currentStep.idPublic
            );
            if (
              !showCompleted &&
              (!currentProductStep || currentProductStep.length == 0)
            ) {
              return false;
            }
          }
          return true;
    }
}