var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.productVisible),function(item,index){return [_c('div',{key:index,on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('div',{staticClass:"row py-3"},[_c('div',{staticClass:"col-auto align-items-start"},[(!item.isDetached)?_c('i',{staticClass:"mdi mdi-36px mdi-cube-outline"}):_vm._e(),(item.isDetached)?_c('i',{staticClass:"mdi mdi-36px mdi-puzzle-outline"}):_vm._e()]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row justify-content-start align-items-center pt-2"},[_c('div',[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.room),expression:"item.room"}]},[_c('b',[_vm._v(_vm._s(_vm.$t("general.room"))+":")]),_vm._v(" "+_vm._s(item.room)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.kitName),expression:"item.kitName"}]},[_c('b',[_vm._v(_vm._s(_vm.$t("general.kit"))+":")]),_vm._v(" "+_vm._s(item.kitName)+" ")])]),_c('div',{staticClass:"ml-auto"})])]),_c('div',{staticClass:"col-auto ml-auto"},[_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[_c('div',[(item.isBackOrder)?_c('span',{staticClass:"\n                  badge badge-danger\n                  p-2\n                  m-1\n                  font-weight-bold font-size-14\n                  align-middle\n                  m-bo\n                "},[_vm._v(_vm._s(_vm.$t("general.bo")))]):_vm._e(),(item.isDamaged)?_c('span',{staticClass:"\n                  badge badge-secondary badge-warning\n                  p-2\n                  m-1\n                  font-size-16\n                "},[_c('i',{staticClass:"mdi ico-damaged align-middle"})]):_vm._e(),(_vm.hasNoProductStep(item))?_c('span',{staticClass:"badge badge-secondary p-2 m-1",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.$t("general.nostep"))+" ")]):_vm._e()]),(
                !_vm.isProductStepCompleted(item) &&
                !_vm.hasNoProductStep(item) &&
                !_vm.isProductDamaged(item) &&
                !_vm.isProductBackOrder(item)
              )?_c('div',{attrs:{"role":"button"},on:{"click":function($event){return _vm.completeProduct(item)}}},[_c('i',{staticClass:"mdi mdi-36px mdi-checkbox-blank-outline mx-2"})]):_vm._e(),(
                _vm.isProductStepCompleted(item) &&
                !(
                  _vm.hasNoProductStep(item) ||
                  _vm.isProductDamaged(item) ||
                  _vm.isProductBackOrder(item)
                )
              )?_c('div',[_c('i',{staticClass:"\n                  mdi mdi-36px mdi-checkbox-marked-outline\n                  text-success\n                  mx-2\n                "})]):_vm._e(),(
                _vm.hasNoProductStep(item) ||
                _vm.isProductDamaged(item) ||
                _vm.isProductBackOrder(item)
              )?_c('div',[_c('i',{staticClass:"mdi mdi-36px mdi-checkbox-marked-outline mx-2"})]):_vm._e(),(item.isKit)?_c('b-button',{staticClass:"my-2",on:{"click":function($event){return _vm.toggleItemDetail(item)}}},[_c('i',{class:{
                  mdi: true,
                  'mdi-18px': true,
                  'mdi-chevron-down': !_vm.isShowed(item),
                  'mdi-chevron-up': _vm.isShowed(item),
                }})]):_vm._e(),(item.isBackOrder || item.isDamaged)?_c('b-button',{staticClass:"my-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toggleItemDetail(item)}}},[_c('i',{class:{
                  mdi: true,
                  'mdi-18px': true,
                  'mdi-chevron-down': !_vm.isShowed(item),
                  'mdi-chevron-up': _vm.isShowed(item),
                }})]):_vm._e()],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowed(item)),expression:"isShowed(item)"}],staticClass:"row"},[_c('div',{staticClass:"offset-1"},[(
              item.parts.length ==
              item.parts.filter(function (x) { return x.isDamaged; }).length
            )?_c('div',[_c('i',{staticClass:"mdi mdi-36px ico-damaged text-warning align-middle mr-2"}),_vm._v(" "+_vm._s(_vm.$t("general.allparts"))+" ")]):_vm._e(),(
              item.parts.length ==
              item.parts.filter(function (x) { return x.isBackOrder; }).length
            )?_c('div',[_c('span',{staticClass:"\n                font-weight-bold font-size-20\n                text-danger\n                align-middle\n                mr-2\n              "},[_vm._v(_vm._s(_vm.$t("general.bo")))]),_vm._v(" "+_vm._s(_vm.$t("general.allparts"))+" ")]):_vm._e(),_vm._l((item.parts.filter(
              function (x) { return x.isDamaged || x.isBackOrder; }
            )),function(part,index){return _c('div',{key:index,staticClass:"m-2"},[(
                !(
                  item.parts.length ==
                  item.parts.filter(function (x) { return x.isDamaged; }).length
                ) && part.isDamaged
              )?_c('i',{staticClass:"mdi mdi-24px ico-damaged text-warning align-middle mr-2"}):_vm._e(),(
                !(
                  item.parts.length ==
                  item.parts.filter(function (x) { return x.isBackOrder; }).length
                ) && part.isBackOrder
              )?_c('span',{staticClass:"font-weight-bold font-size-16 text-danger mr-2"},[_vm._v(_vm._s(_vm.$t("general.bo")))]):_vm._e(),(
                (!(
                  item.parts.length ==
                  item.parts.filter(function (x) { return x.isDamaged; }).length
                ) &&
                  part.isDamaged) ||
                (!(
                  item.parts.length ==
                  item.parts.filter(function (x) { return x.isBackOrder; }).length
                ) &&
                  part.isBackOrder)
              )?_c('span',[_vm._v(_vm._s(part.name))]):_vm._e()])})],2)])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }