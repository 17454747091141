<script>
import ApiService from "@/services/jobsiteTrackingService";
import Loading from "vue-loading-overlay";
export default {
  components: { Loading },
  props: ["value"],
  data() {
    return {
      isCommentsLoading: false,
      hasErrorLoadingComments: false,
      newComment: "",
      newCommentVisible: false,
      comments: [],
    };
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {},
  methods: {
    loadData() {
      this.isCommentsLoading = true;
      ApiService.getComments(this.$route.params.workorderid)
        .then(
          (response) => {
            this.comments = response.data.data;
            this.hasErrorLoadingComments = false;
          },
          (error) => {
            window.console.error(error);
            this.hasErrorLoadingComments = true;
          }
        )
        .finally(() => {
          this.isCommentsLoading = false;
        });
    },
    toggleComment() {
      this.newCommentVisible = !this.newCommentVisible;
    },
    newCommentShown() {
      this.$refs.newcommenttextarea.focus();
    },
    addcomment() {
      this.isCommentsLoading = true;
      var comment = {
        message: this.newComment,
      };
      comment.workOrderStepIdPublic = this.value;
      ApiService.createComment(comment)
        .then((result) => {
          this.comments.unshift(result.data.data);
          this.newComment = "";
          this.newCommentVisible = false;
        })
        .finally(() => (this.isCommentsLoading = false));
    },
  },
  computed: {},
  watch: {},
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <loading
        :active.sync="isCommentsLoading"
        :can-cancel="false"
        color="#f1b44c"
        :width="70"
        :height="70"
        loader="dots"
        :is-full-page="false"
      >
      </loading>
      <div style="display: flex; align-items: center">
        <i class="mdi mdi-36px mdi-comment-text-outline"></i>
        <h5 style="margin-left: 12px">{{ $t("general.comments") }}</h5>
        <i
          @click="toggleComment"
          class="fas fa-plus text-success"
          style="margin-left: auto; font-size: 1.3em; cursor: pointer"
        ></i>
      </div>
      <b-collapse
        id="collapse-new-comment"
        v-model="newCommentVisible"
        class="mt-2"
        @shown="newCommentShown"
      >
        <div>
          <textarea
            ref="newcommenttextarea"
            v-on:keydown.enter.ctrl.exact.prevent="addcomment"
            rows="6"
            style="width: 100%"
            v-model="newComment"
          />
          <div class="flex-box" style="white-space: nowrap">
            <b-button variant="info" @click="addcomment">{{
              $t("general.addcomment")
            }}</b-button>
          </div>
        </div>
      </b-collapse>
      <div v-for="(comment, index) in comments" :key="index" class="m-2 mb-4">
        <div class="d-flex justify-content-between">
          <div
            class="rounded-circle header-profile-user"
            style="
              height: 27px;
              width: 27px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f3a821;
            "
          >
            <span
              style="
                font-weight: 600;
                color: white;
                font-size: 1.1em;
                vertical-align: center;
              "
              >{{ comment.userName.charAt(0) }}</span
            >
          </div>
          <div>
            <span
              class="badge bg-secondary bg-warning p-2 m-1"
              style="font-size: 1em"
            >
              {{ comment.stepName }}
            </span>
          </div>
        </div>
        <div>
          <b>{{ comment.userName }}</b> /
          {{ $dayjs(comment.created).format("DD-MM-YYYY") }}
        </div>
        <div class="text-center m-1 mt-2">{{ comment.message }}</div>
      </div>
      <div v-show="comments.length == 0 && !hasErrorLoadingComments">
        {{ $t("general.nocomments") }}
      </div>
      <div v-show="hasErrorLoadingComments">
        {{ $t("general.errorcomments") }}
      </div>
    </div>
  </div>
</template>