<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import ApiService from "@/services/jobsiteTrackingService";
import ProductHelper from "@/utils/productHelper";
import WorkOrderHelper from "@/utils/workOrderHelper";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AlertModal from "@/components/modals/alertModal";
import ConfirmCompletedModal from "@/components/modals/confirmCompletedModal";
import PageHeader from "@/components/page-header";
import ProductTable from "./productTable";
import BarCodeReader from "@/components/barCodeReader";
import FilesModal from "@/components/modals/filesModal";
import SignatureModal from "@/components/modals/signatureModal";
import Comments from "./list-message.vue";
import UserConfigMixin from "@/mixins/userConfigMixin";

export default {
  mixins: [UserConfigMixin],
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    Layout,
    Multiselect,
    Loading,
    AlertModal,
    ConfirmCompletedModal,
    ProductTable,
    BarCodeReader,
    FilesModal,
    Comments,
    SignatureModal,
  },
  data() {
    return {
      packingWorkOrder: {
        name: "",
        products: [],
      },
      reasons: [],
      currentStep: null,
      isLoading: false,
      isAlertsLoading: false,
      typeFilter: "0",
      stateFilter: "0",
      packingFilter: null,
      packings: [],
      alertMode: false,
      showProducts: false,
      showFilters: false,
      showDetached: false,
      showBO: false,
      showDamaged: false,
      showCompleted: true,
      toggleKitView: false,
      chartOptions: {
        chart: {
          height: "auto",
          width: "100%",
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#34c38f"],
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            track: {
              background: "#f8f9fa",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
            },
            hollow: {
              size: "36%",
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 10,
                offsetX: -5,
                fontSize: "28px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        stroke: {
          dashArray: 0,
        },
        labels: ["Storage"],
      },
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "isDetached",
          label: this.$t("general.types"),
        },
        {
          key: "name",
          label: this.$t("general.name"),
        },
        {
          key: "room",
          label: this.$t("general.room"),
        },
        {
          key: "kitName",
          label: this.$t("general.kit"),
        },
        {
          key: "status",
          label: this.$t("general.status"),
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
    nbBO() {
      return this.packingWorkOrder.products.filter((x) => x.isBackOrder).length;
    },
    nbDamaged() {
      return this.packingWorkOrder.products.filter((x) => x.isDamaged).length;
    },
    nbProduct() {
      return this.packingWorkOrder.products.filter((x) => !x.isDetached).length;
    },
    nbDetached() {
      return this.packingWorkOrder.products.filter((x) => x.isDetached).length;
    },
    nbProductCompleted() {
      if (!this.currentStep) {
        return 0;
      }
      var productArrays = this.packingWorkOrder.products.filter(
        (x) =>
          !x.isDetached &&
          (ProductHelper.isProductStepCompleted(x, this.currentStep) ||
            ProductHelper.hasNoProductStep(x, this.currentStep) ||
            ProductHelper.isProductDamaged(x) ||
            ProductHelper.isProductBackOrder(x))
      );
      return productArrays.length;
    },
    nbDetachedCompleted() {
      if (!this.currentStep) {
        return 0;
      }
      var productArrays = this.packingWorkOrder.products.filter(
        (x) =>
          x.isDetached &&
          (ProductHelper.isProductStepCompleted(x, this.currentStep) ||
            ProductHelper.hasNoProductStep(x, this.currentStep) ||
            ProductHelper.isProductDamaged(x) ||
            ProductHelper.isProductBackOrder(x))
      );
      return productArrays.length;
    },
    percentCompleted() {
      if (this.nbProduct + this.nbDetached == 0) {
        return 0;
      }
      return Math.round(
        (100 * (this.nbProductCompleted + this.nbDetachedCompleted)) /
          (this.nbProduct + this.nbDetached)
      );
    },
    steps() {
      if (!this.packingWorkOrder.workOrderSteps) {
        return [];
      }
      return this.packingWorkOrder.workOrderSteps.map((x) => x.step);
    },
    currentStepIdPublic() {
      if (!this.currentStep) {
        return null;
      }
      return this.currentStep.idPublic;
    },
    currentWorkOrderStepIdPublic() {
      return this.packingWorkOrder?.workOrderSteps?.find(
        (x) => this.currentStepIdPublic == x.step.idPublic
      )?.idPublic;
    },
    fullAddress() {
      return WorkOrderHelper.getFullAddress(this.packingWorkOrder);
    },
    phone() {
      var cleaned = ("" + this.packingWorkOrder.clientPhone).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return this.packingWorkOrder.clientPhone ?? null;
    },
    products() {
      var productArray = this.packingWorkOrder.products;
      if (this.showProducts) {
        productArray = productArray.filter((x) => !x.isDetached);
      }
      if (this.showDetached) {
        productArray = productArray.filter((x) => x.isDetached);
      }
      if (this.showDamaged) {
        productArray = productArray.filter((x) => x.isDamaged);
      }
      if (this.showBO) {
        productArray = productArray.filter((x) => x.isBackOrder);
      }
      if (!this.packingFilter || !this.packingFilter.idPublic) {
        return productArray;
      }
      return productArray.filter(
        (x) => x.packingIdPublic == this.packingFilter.idPublic
      );
    },
    locale: function () {
      return this.$i18n.locale;
    },
    allProductCompleted: function () {
      return (
        this.currentStep == undefined ||
        this.products.filter(
          (x) =>
            !ProductHelper.isProductStepCompleted(x, this.currentStep) &&
            !(
              ProductHelper.hasNoProductStep(x, this.currentStep) ||
              ProductHelper.isProductDamaged(x) ||
              ProductHelper.isProductBackOrder(x)
            )
        ).length == 0
      );
    },
    currentWorkOrderStepCompleted: function () {
      return (
        this.packingWorkOrder?.workOrderSteps?.find(
          (x) => x.step.idPublic == this.currentStepIdPublic
        )?.completedJobsite ?? true
      );
    },
  },
  watch: {
    locale: function () {
      this.packings.find((x) => x.idPublic == null).name =
        this.$t("general.all");
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.isAlertsLoading = true;
      var packingPromise = ApiService.getPackingWorkOrder(
        this.$route.params.workorderid
      ).then(
        (response) => {
          this.packingWorkOrder = response.data.data;
          this.currentStep = this.steps.filter(
            (x) => x.idPublic == self.$store.state.userconfig.config.defaultStep
          )[0];
          if (
            (this.currentStep == null || this.currentStep == undefined) &&
            this.steps.length != 0
          ) {
            this.currentStep = this.steps[0];
          }
          this.packings = this.packingWorkOrder.products
            .map((x) => ({ idPublic: x.packingIdPublic, name: x.packingName }))
            .filter(
              (value, index, self) =>
                self.findIndex(
                  (element) => element.idPublic == value.idPublic
                ) === index
            );
          this.packings.push({ idPublic: null, name: this.$t("general.all") });
          if (this.$route.query.packing) {
            this.packingFilter = this.packings.find(
              (x) => x.idPublic == this.$route.query.packing
            );
          } else {
            this.packingFilter = this.packings.find((x) => x.idPublic == null);
          }
          this.isLoading = false;
        },
        (error) => {
          window.console.error(error);
        }
      );
      let parts = null;
      var partPromise = ApiService.getParts(
        this.$route.params.workorderid
      ).then(
        (response) => {
          parts = response.data.data;
        },
        (error) => {
          window.console.error(error);
        }
      );
      var reasonPromise = ApiService.getReasons().then(
        (response) => {
          this.reasons = response.data.data;
        },
        (error) => {
          window.console.error(error);
        }
      );
      var self = this;
      Promise.all([packingPromise, reasonPromise, partPromise]).then(() => {
        this.isAlertsLoading = false;
        this.sortParts(parts);
      });
    },
    changeStep(step) {
      this.$store.dispatch("userconfig/setUserConfig", {
        userId: this.$msal.msalInstance.getAllAccounts()[0].localAccountId,
        defaultStep: step.idPublic,
      });
    },
    confirmCompleted(productData) {
      this.$refs.confirmModal.openEventDialog(productData).then(
        (products) => this.completeProduct(products.map((x) => x.idPublic)),
        () => {}
      );
    },
    completeProduct(idPublics) {
      this.isLoading = true;
      return ApiService.completeProduct({
        ProductIdPublics: idPublics,
        StepIdPublic: this.currentStep.idPublic,
      }).then((res) => {
        for (var index in this.packingWorkOrder.products) {
          var productResource = res.data.data.find(
            (x) => x.idPublic == this.packingWorkOrder.products[index].idPublic
          );
          if (productResource) {
            this.replaceProduct(productResource, index);
          }
        }
        this.isLoading = false;
      });
    },
    replaceProduct(newProduct, index) {
      this.packingWorkOrder.products[index] = newProduct;
      this.packingWorkOrder.products.__ob__.dep.notify();
    },
    async completeWorkOrderStep() {
      if (this.stepNeedSignature(this.currentStepIdPublic)) {
        await this.$refs.signatureModal.openEventDialog({
          id: this.packingWorkOrder.name,
          title: this.packingWorkOrder.name,
          userCreated: false,
          type: this.currentStep.name,
        });
      }
      this.isLoading = true;
      ApiService.completeWorkOrderSteps(
        this.packingWorkOrder.idPublic,
        this.currentStepIdPublic
      ).then(() => {
        this.trackEvent("CompleteWorkOrderStep")
        this.getData();
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    completeAllProduct() {
      var products = this.products.filter(
        (x) =>
          !ProductHelper.isProductStepCompleted(x, this.currentStep) &&
          !(
            ProductHelper.hasNoProductStep(x, this.currentStep) ||
            ProductHelper.isProductDamaged(x) ||
            ProductHelper.isProductBackOrder(x)
          )
      );
      this.confirmCompleted(products);
    },
    scan(barcode) {
      var barcodeitems =
        this.packingWorkOrder.productBarcodeGetResources.filter(
          (x) => x.barcode.toLowerCase() == barcode
        );
      if (barcodeitems.length == 0) {
        this.$bvToast.toast(this.$t("general.unrecognisedscan"), {
          title: this.$t("general.wrongscan"),
          autoHideDelay: 5000,
          appendToast: false,
          variant: "danger",
          solid: true,
        });

        this.$refs.barcodereader.wrongscan();
        return;
      }

      var packingitems = barcodeitems;
      if (this.packingFilter && this.packingFilter.idPublic) {
        packingitems = packingitems.filter(
          (x) => x.packingIdPublic == this.packingFilter.idPublic
        );
        if (packingitems.length == 0) {
          this.$bvToast.toast(this.$t("general.wrongpacking"), {
            title: this.$t("general.wrongscan"),
            autoHideDelay: 5000,
            appendToast: false,
            variant: "danger",
            solid: true,
          });
          this.$refs.barcodereader.wrongjob();
          return;
        }
      }

      var workOrderItems = packingitems.filter(
        (x) => x.workOrderIdPublic == this.packingWorkOrder.idPublic
      );
      if (workOrderItems.length == 0) {
        this.$bvToast.toast(this.$t("general.wrongworkorder"), {
          title: this.$t("general.wrongscan"),
          autoHideDelay: 5000,
          appendToast: false,
          variant: "danger",
          solid: true,
        });
        this.$refs.barcodereader.wrongjob();
        return;
      }
      var productIdPublics = workOrderItems.map((x) => x.idPublic);
      var product;
      if (productIdPublics.length == 1) {
        product = this.packingWorkOrder.products.find(
          (x) => x.idPublic == productIdPublics[0]
        );
        if (
          product &&
          (ProductHelper.hasNoProductStep(product, this.currentStep) ||
            ProductHelper.isProductStepCompleted(product, this.currentStep))
        ) {
          this.$bvToast.toast(
            product.name + " " + this.$t("general.alreadyscanned"),
            {
              title: this.$t("general.wrongscan"),
              autoHideDelay: 5000,
              appendToast: false,
              variant: "danger",
              solid: true,
            }
          );
          this.$refs.barcodereader.wrongjob();
          return;
        }
      }

      this.completeProduct(productIdPublics).then(() => {
        if (product) {
          this.$bvToast.toast(
            product.name + " " + this.$t("general.productscompleted"),
            {
              title: this.$t("general.goodscan"),
              autoHideDelay: 5000,
              appendToast: false,
              variant: "success",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(this.$t("general.productscompleted"), {
            title: this.$t("general.goodscan"),
            autoHideDelay: 5000,
            appendToast: false,
            variant: "success",
            solid: true,
          });
        }
        this.$refs.barcodereader.goodscan();
        if (productIdPublics.length == 1) {
          var index = 0;
          if (this.toggleKitView) {
            var kit = Object.keys(this.$refs.productTable.kitProductHash).find(
              (kit) =>
                this.$refs.productTable.kitProductHash[kit].products.filter(
                  (x) => x.idPublic == productIdPublics[0]
                ).length != 0
            );
            if (kit) {
              this.$refs.productTable.kitProductHash[kit].isOpen = true;
              index = this.$refs.productTable.visibleKitProducts.findIndex(
                (x) => x.idPublic == productIdPublics[0]
              );
            }
          } else {
            index = this.$refs.productTable.visibleProducts.findIndex(
              (x) => x.idPublic == productIdPublics[0]
            );
          }
          if (index) {
            var element =
              this.$refs.productTable.$refs.productTable.$refs["item-rows"][
                index
              ];
            if (!element) {
              setTimeout(() => {
                element =
                  this.$refs.productTable.$refs.productTable.$refs["item-rows"][
                    index
                  ];
                if (element) {
                  var top = element.$el.offsetTop;
                  this.$refs.scollpart.scroll(0, top);
                }
              }, 500);
            } else {
              var top = element.$el.offsetTop;
              this.$refs.scollpart.scroll(0, top);
            }
          }
        }
      });
    },
    getToComments() {
      var top =
        this.$refs.comments.$el.offsetTop + (this.showFilters ? 285 : 0);
      this.$refs.scollpart.scroll(0, top);
      this.showFilters = false;
    },
    changeAlertMode(event) {
      if (this.isAlertsLoading) {
        return;
      }
      this.alertMode = !this.alertMode;
      event.currentTarget.blur();
    },
    openAlert(product) {
      if (!this.alertMode) {
        return;
      }
      if (
        product.isDamaged &&
        product.parts.filter((x) => !x.isDamaged).length == 0
      ) {
        return;
      }
      this.$refs.alertModal
        .openAlert({
          product: product,
          reasons: this.reasons,
          workOrderName: this.packingWorkOrder.name,
        })
        .then(
          (result) => {
            result.stepIdPublic = this.currentStepIdPublic;
            ApiService.sendAlert(result);
          },
          () => {}
        )
        .finally(() => {
          this.alertMode = false;
        });
    },
    sortParts(parts) {
      for (var i = 0; i < parts.length; i++) {
        var product = this.packingWorkOrder.products.find(
          (x) => x.idPublic == parts[i].productIdPublic
        );
        if (!product.parts) {
          product.parts = [];
        }
        product.parts.push(parts[i]);
      }
    },
    filterProduct(event) {
      this.showProducts = !this.showProducts;
      this.showDetached = false;
      event.currentTarget.blur();
    },
    filterDetached(event) {
      this.showDetached = !this.showDetached;
      this.showProducts = false;
      event.currentTarget.blur();
    },
    filterBO(event) {
      this.showBO = !this.showBO;
      this.showDamaged = false;
      event.currentTarget.blur();
    },
    filterDamaged(event) {
      this.showDamaged = !this.showDamaged;
      this.showBO = false;
      event.currentTarget.blur();
    },
  },
  created() {
    this.$store
      .dispatch(
        "userconfig/getUserConfig",
        this.$msal.msalInstance.getAllAccounts()[0].localAccountId
      )
      .then((res) => {
        if (res.defaultStep !== null) {
          this.currentStep = this.steps.filter(
            (x) => x.idPublic == res.defaultStep
          )[0];
        }
      });
    this.getData();
  },
};
</script>
<template>
  <Layout>
    <BarCodeReader @scan="scan" ref="barcodereader" />
    <PageHeader :title="'Job: ' + packingWorkOrder.name" />
    <div class="row">
      <div class="col-xxl-3 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <div
                role="button"
                class="p-md-2 pr-md-3 d-xxl-none font-size-16"
                @click="showFilters = !showFilters"
              >
                <i class="fa fa-bars"></i>
              </div>
              <h5 class="m-2">{{ $t("general.status") }}</h5>
              <multiselect
                v-model="currentStep"
                :show-labels="false"
                :options="steps"
                label="name"
                track-by="idPublic"
                :allow-empty="false"
                :multiple="false"
                @select="changeStep"
              >
              </multiselect>
              <b-button
                variant="outline-light"
                :class="{
                  'alert-mode-on': alertMode,
                  'd-lg-none': true,
                  'ml-1': true,
                  'ml-md-3': true,
                  'pt-1': true,
                  'px-1': true,
                  'pb-0': true,
                  'no-hover': !alertMode,
                  'bdr-10': true,
                }"
                @click="changeAlertMode"
              >
                <i
                  :class="{
                    mdi: true,
                    'mdi-36px': true,
                    'ico-damaged': true,
                  }"
                ></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-xxl-2': true,
          'col-lg-6': true,
          'd-none': !showFilters,
          'd-lg-block': true,
          'order-xxl-3': true,
        }"
      >
        <div class="card">
          <div class="card-body">
            <div
              class="d-flex align-items-center justify-content-around"
              style="height: 40px"
            >
              <b-button
                role="button"
                :class="{
                  'alert-mode-on': alertMode,
                  'd-none': true,
                  'd-lg-block': true,
                  'p-1': true,
                  'pt-2': true,
                  'px-2': true,
                  'no-hover': !alertMode,
                  'bdr-10': true,
                }"
                variant="outline-light"
                @click="changeAlertMode"
              >
                <i
                  :class="{
                    mdi: true,
                    'mdi-36px': true,
                    'ico-damaged': true,
                    'text-warning': !alertMode,
                  }"
                ></i>
              </b-button>
              <div role="button" @click="getToComments">
                <i class="mdi mdi-36px mdi-comment-text-outline"></i>
              </div>
              <div
                role="button"
                @click="$refs.filesModal.openEventDialog(packingWorkOrder)"
              >
                <i class="mdi mdi-36px mdi-file-document-multiple-outline"></i>
              </div>
              <div role="button" v-if="fullAddress">
                <a
                  target="_blank"
                  :href="'http://maps.google.com/?q=' + fullAddress"
                  ><i
                    class="mdi mdi-36px mdi-map-marker-outline text-success"
                  ></i
                ></a>
              </div>
              <div v-if="!fullAddress">
                <i class="mdi mdi-36px mdi-map-marker-outline"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-xxl-4': true,
          'col-lg-6': true,
          'd-none': !showFilters,
          'd-xxl-block': true,
        }"
      >
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-around">
              <div class="d-flex flex-row align-items-center my-2 my-xl-0 mr-2">
                <h5 class="my-0 mr-2">{{ $t("general.types") }}</h5>
                <b-button-group size="md">
                  <b-button
                    :class="{ active: showProducts }"
                    @click="filterProduct"
                    variant="secondary"
                  >
                    <span class="d-none d-lg-block">
                      {{ $t("general.products") }}</span
                    ><i
                      class="mdi mdi-24px mdi-cube-outline d-block d-lg-none"
                    ></i>
                  </b-button>
                  <b-button
                    :class="{ active: showDetached }"
                    @click="filterDetached"
                    variant="secondary"
                  >
                    <span class="d-none d-lg-block">{{
                      $t("general.accessories")
                    }}</span>
                    <i
                      class="mdi mdi-24px mdi-puzzle-outline d-block d-lg-none"
                    ></i>
                  </b-button>
                </b-button-group>
              </div>
              <div class="d-flex flex-row align-items-center my-lg-0 my-2">
                <h5 class="my-0 mr-2">{{ $t("general.state") }}</h5>
                <b-button-group size="md">
                  <b-button
                    :class="{ active: showBO }"
                    @click="filterBO"
                    variant="danger"
                  >
                    <span class="d-block d-lg-none font-weight-bold">{{
                      $t("general.bo")
                    }}</span>
                    <span class="d-none d-lg-block">{{
                      $t("general.bo")
                    }}</span>
                  </b-button>
                  <b-button
                    :class="{ active: showDamaged }"
                    @click="filterDamaged"
                    variant="warning"
                  >
                    <span class="d-none d-lg-block">{{
                      $t("general.damaged")
                    }}</span>
                    <i class="mdi mdi-36px ico-damaged d-block d-lg-none"></i>
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-xxl-3': true,
          'col-lg-6': true,
          'd-none': !showFilters,
          'd-xxl-block': true,
        }"
      >
        <div class="card">
          <div class="card-body">
            <div
              v-if="packings.length > 1"
              class="d-flex align-items-center justify-content-center"
            >
              <h5 class="m-2">{{ $t("general.packings") }}</h5>
              <multiselect
                v-model="packingFilter"
                :show-labels="false"
                :options="packings"
                label="name"
                track-by="idPublic"
                :allow-empty="true"
                :multiple="false"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      ref="scollpart"
      style="
        flex: 1 1 1px;
        min-height: 0;
        overflow-y: scroll;
        position: relative;
      "
    >
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="d-flex justify-content-start col">
                <div
                  v-for="(
                    workOrderStep, index
                  ) in packingWorkOrder.workOrderSteps"
                  :key="index"
                  class="p-1"
                >
                  <span
                    class="badge badge-secondary p-2"
                    style="font-size: 1em"
                    v-if="workOrderStep.completedJobsite"
                  >
                    <i
                      class="mdi mdi-check align-middle"
                      v-if="workOrderStep.completedJobsite"
                    ></i>
                    {{ workOrderStep.step.name }}
                  </span>
                  <b-button
                    disabled
                    role="text"
                    v-if="!workOrderStep.completedJobsite"
                    variant="outline-primary"
                    class="p-1"
                    style="font-size: 1em"
                    >{{ workOrderStep.step.name }}</b-button
                  >
                </div>
              </div>
              <div class="d-flex align-items-center col-auto">
                <h5 class="p-1 m-2">
                  <i class="mdi mdi-24px custom-kit align-middle m-1"></i
                  >{{ $t("general.kit") }}
                </h5>
                <b-form-checkbox
                  switch
                  size="lg"
                  v-model="toggleKitView"
                  class=""
                ></b-form-checkbox>
                <h5 class="m-2 p-1">{{ $t("general.all") }}</h5>
                <b-form-checkbox
                  switch
                  size="lg"
                  v-model="showCompleted"
                  class=""
                ></b-form-checkbox>
              </div>
            </div>
            <div class="row p-1">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <ProductTable
                ref="productTable"
                @product-clicked="openAlert"
                @completeProduct="confirmCompleted"
                :showCompleted="showCompleted"
                :currentStep="currentStep"
                :toggleKitView="toggleKitView"
                :products="products"
              />
            </div>
            <div class="row footer-nav">
              <div class="d-flex">
                <b-button
                  v-if="userConfig.canCheckAll"
                  :disabled="allProductCompleted"
                  class="m-1"
                  variant="primary"
                  @click="completeAllProduct"
                  >{{ $t("general.checkall") }}</b-button
                >
                <b-button
                  v-if="userConfig.canCompleteStep"
                  :disabled="currentWorkOrderStepCompleted"
                  class="m-1"
                  variant="outline-secondary"
                  @click="completeWorkOrderStep"
                  >{{ $t("general.complete") }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 order-xl-first">
        <div class="card">
          <div class="card-body">
            <div v-if="packingWorkOrder.clientName">
              <b>{{ $t("general.clientname") }} :</b>
              {{ packingWorkOrder.clientName }}
            </div>
            <div v-if="phone">
              <b>{{ $t("general.phone") }} :</b> {{ phone }}
            </div>
            <div v-if="fullAddress && fullAddress != ''">
              <b>{{ $t("general.address") }} :</b>
              {{ packingWorkOrder.clientAddress }}
            </div>
            <div>{{ packingWorkOrder.clientAddressLine2 }}</div>
            <div>
              {{ packingWorkOrder.clientCity }}
              {{ packingWorkOrder.clientState }}
              {{ packingWorkOrder.clientZipCode }}
            </div>
            <div v-if="packingWorkOrder.deliveryDate">
              <b>{{ $t("general.deliverydate") }} :</b>
              {{
                $dayjs(packingWorkOrder.deliveryDate + "Z").format("YYYY-MM-DD")
              }}
            </div>
            <div v-if="packingWorkOrder.installationDate">
              <b>{{ $t("general.installationdate") }} :</b>
              {{
                $dayjs(packingWorkOrder.installationDate + "Z").format(
                  "YYYY-MM-DD"
                )
              }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-row">
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <span class="font-weight-bold font-size-20 text-danger">{{
                  $t("general.bo")
                }}</span>
                <span class="rounded border border-dark p-2 m-2 px-3">{{
                  nbBO
                }}</span>
              </div>
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <i
                  class="mdi mdi-36px ico-damaged text-warning align-middle"
                ></i>
                <span class="rounded border border-dark p-2 m-2 px-3">{{
                  nbDamaged
                }}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <apexchart
                class="apex-charts"
                dir="ltr"
                :series="[percentCompleted]"
                :options="chartOptions"
              ></apexchart>
            </div>
            <div class="d-flex flex-row">
              <div class="col-6 d-flex justify-content-center">
                <i class="mdi mdi-36px mdi-cube-outline align-middle"></i>
                <span class="rounded border border-dark p-2 m-2"
                  >{{ nbProductCompleted }}/{{ nbProduct }}</span
                >
              </div>
              <div class="col-6 d-flex justify-content-center">
                <i class="mdi mdi-36px mdi-puzzle-outline align-middle"></i>
                <span class="rounded border border-dark p-2 m-2"
                  >{{ nbDetachedCompleted }}/{{ nbDetached }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <Comments ref="comments" v-model="currentWorkOrderStepIdPublic" />
      </div>
    </div>
    <AlertModal ref="alertModal" />
    <ConfirmCompletedModal ref="confirmModal" />
    <FilesModal ref="filesModal" />
    <SignatureModal ref="signatureModal" />
  </Layout>
</template>