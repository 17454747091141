<script>

export default {
  components: {
  },
  data() {
    return {
      showModal: false,
      allParts: false,
      parts: [],
      close: null,
      confirm: null
    };
  },
  created(){

  },
  beforeDestroy() {
  },
  methods: {
    openAlertEventDialog: function(data){
        this.showModal = true;
        this.parts = data;
        return new Promise((resolve, reject) => {
          this.confirm = () => {
            if(this.allParts) {
              resolve([]);
            }
            resolve(this.parts.filter(x => x.isDamaged).map(x => x.idPublic));
          }
          this.close = () => {
            reject();
          }
        }).finally(() => {
          this.showModal = false;
          setTimeout(() => {
            this.parts = [];
          }, 500);
        });
    }
  },  
  watch: {
    allParts(value) {
        for(var i = 0; i <  this.parts.length; i++) {
          this.parts[i].isDamaged = value;
        }
    }
  }
}
</script>

<template>
    <b-modal
        v-model="showModal"
        :title="$t('general.alert')"
        title-class="text-black font-18"
        scrollable
        body-class="p-3"
        id="alertPartModal">
        <p>{{$t("general.pleasechoosedamagedparts")}}</p>
        <div class="form-group">
          <label>{{$t("general.parts")}} :</label>
          <div class="funkyradio">
            <div class="funkyradio-warning">
              <input type="checkbox" id="all-parts-damaged" v-model="allParts" />
              <label for="all-parts-damaged">{{$t("general.allparts")}}</label>
            </div>
            <div v-for="(part, index) in parts" class="funkyradio-warning" :key="index">
              <input type="checkbox" :id="index" v-model="part.isDamaged" />
              <label :for="index">{{part.name}}</label></div>
          </div>
        </div>
        <template #modal-footer class="text-right pt-3">
          <b-button variant="light" @click="close()">{{$t('general.close')}}</b-button>
          <b-button variant="light" @click="confirm()">{{$t('general.confirm')}}</b-button>
        </template>
    </b-modal>
</template>