<script>
import ProductHelper from "@/utils/productHelper";
export default {
  components: {},
  props: ["value", "currentStep", "showCompleted"],
  data() {
    return {
      productShowed: [],
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    rowClicked: function (item) {
      this.$emit("product-clicked", item);
    },
    completeProduct: function (item) {
      this.$emit("product-complete", item);
    },
    isProductStepCompleted(product) {
      return ProductHelper.isProductStepCompleted(product, this.currentStep);
    },
    hasNoProductStep(product) {
      return ProductHelper.hasNoProductStep(product, this.currentStep);
    },
    isProductDamaged(product) {
      return ProductHelper.isProductDamaged(product);
    },
    isProductBackOrder(product) {
      return ProductHelper.isProductBackOrder(product);
    },
    isShowed(item) {
      return this.productShowed.includes(item);
    },
    toggleItemDetail(item) {
      if (this.productShowed.includes(item)) {
        this.productShowed.pop(item);
      } else {
        this.productShowed.push(item);
      }
    },
  },
  computed: {
    productVisible: function () {
      return this.value.filter((x) =>
        ProductHelper.isProductVisible(x, this.currentStep, this.showCompleted)
      );
    },
  },
  watch: {},
};
</script>
<template>
  <div>
    <template v-for="(item, index) in productVisible">
      <div :key="index" @click="rowClicked(item)">
        <div class="row py-3">
          <div class="col-auto align-items-start">
            <i
              class="mdi mdi-36px mdi-cube-outline"
              v-if="!item.isDetached"
            ></i>
            <i
              class="mdi mdi-36px mdi-puzzle-outline"
              v-if="item.isDetached"
            ></i>
          </div>
          <div class="col">
            <div class="row justify-content-start align-items-center pt-2">
              <div>
                <div>{{ item.name }}</div>
                <div v-show="item.room">
                  <b>{{ $t("general.room") }}:</b> {{ item.room }}
                </div>
                <div v-show="item.kitName">
                  <b>{{ $t("general.kit") }}:</b> {{ item.kitName }}
                </div>
              </div>
              <div class="ml-auto"></div>
            </div>
          </div>
          <div class="col-auto ml-auto">
            <div class="d-flex flex-row justify-content-end align-items-center">
              <div>
                <span
                  v-if="item.isBackOrder"
                  class="
                    badge badge-danger
                    p-2
                    m-1
                    font-weight-bold font-size-14
                    align-middle
                    m-bo
                  "
                  >{{$t("general.bo")}}</span
                >
                <span
                  class="
                    badge badge-secondary badge-warning
                    p-2
                    m-1
                    font-size-16
                  "
                  v-if="item.isDamaged"
                >
                  <i class="mdi ico-damaged align-middle"></i>
                </span>
                <span
                  class="badge badge-secondary p-2 m-1"
                  style="font-size: 1em"
                  v-if="hasNoProductStep(item)"
                >
                  {{ $t("general.nostep") }}
                </span>
              </div>
              <div
                v-if="
                  !isProductStepCompleted(item) &&
                  !hasNoProductStep(item) &&
                  !isProductDamaged(item) &&
                  !isProductBackOrder(item)
                "
                role="button"
                @click="completeProduct(item)"
              >
                <i class="mdi mdi-36px mdi-checkbox-blank-outline mx-2"></i>
              </div>
              <div
                v-if="
                  isProductStepCompleted(item) &&
                  !(
                    hasNoProductStep(item) ||
                    isProductDamaged(item) ||
                    isProductBackOrder(item)
                  )
                "
              >
                <i
                  class="
                    mdi mdi-36px mdi-checkbox-marked-outline
                    text-success
                    mx-2
                  "
                ></i>
              </div>
              <div
                v-if="
                  hasNoProductStep(item) ||
                  isProductDamaged(item) ||
                  isProductBackOrder(item)
                "
              >
                <i class="mdi mdi-36px mdi-checkbox-marked-outline mx-2"></i>
              </div>
              <b-button
                v-if="item.isKit"
                @click="toggleItemDetail(item)"
                class="my-2"
                ><i
                  :class="{
                    mdi: true,
                    'mdi-18px': true,
                    'mdi-chevron-down': !isShowed(item),
                    'mdi-chevron-up': isShowed(item),
                  }"
                ></i
              ></b-button>
              <b-button
                size="sm"
                v-if="item.isBackOrder || item.isDamaged"
                @click="toggleItemDetail(item)"
                class="my-2"
                ><i
                  :class="{
                    mdi: true,
                    'mdi-18px': true,
                    'mdi-chevron-down': !isShowed(item),
                    'mdi-chevron-up': isShowed(item),
                  }"
                ></i
              ></b-button>
            </div>
          </div>
        </div>
        <div class="row" v-show="isShowed(item)">
          <div class="offset-1">
            <div
              v-if="
                item.parts.length ==
                item.parts.filter((x) => x.isDamaged).length
              "
            >
              <i
                class="mdi mdi-36px ico-damaged text-warning align-middle mr-2"
              ></i>
              {{ $t("general.allparts") }}
            </div>
            <div
              v-if="
                item.parts.length ==
                item.parts.filter((x) => x.isBackOrder).length
              "
            >
              <span
                class="
                  font-weight-bold font-size-20
                  text-danger
                  align-middle
                  mr-2
                "
                >{{$t("general.bo")}}</span
              >
              {{ $t("general.allparts") }}
            </div>
            <div
              v-for="(part, index) in item.parts.filter(
                (x) => x.isDamaged || x.isBackOrder
              )"
              :key="index"
              class="m-2"
            >
              <i
                v-if="
                  !(
                    item.parts.length ==
                    item.parts.filter((x) => x.isDamaged).length
                  ) && part.isDamaged
                "
                class="mdi mdi-24px ico-damaged text-warning align-middle mr-2"
              ></i>
              <span
                v-if="
                  !(
                    item.parts.length ==
                    item.parts.filter((x) => x.isBackOrder).length
                  ) && part.isBackOrder
                "
                class="font-weight-bold font-size-16 text-danger mr-2"
                >{{$t("general.bo")}}</span
              >
              <span
                v-if="
                  (!(
                    item.parts.length ==
                    item.parts.filter((x) => x.isDamaged).length
                  ) &&
                    part.isDamaged) ||
                  (!(
                    item.parts.length ==
                    item.parts.filter((x) => x.isBackOrder).length
                  ) &&
                    part.isBackOrder)
                "
                >{{ part.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>