export default {
    getFullAddress(workOrder) {
        var returnStr = "";
        returnStr += workOrder.clientAddress ?? "";
        returnStr += (returnStr == "" ? "" : '+') + (workOrder.clientAddressLine2 ?? "");
        returnStr += (returnStr == "" ? "" : '+') + (workOrder.clientCity ?? "");
        returnStr += (returnStr == "" ? "" : '+') + (workOrder.clientState ?? "");
        returnStr += (returnStr == "" ? "" : '+') + (workOrder.clientZipCode ?? "");
        return returnStr.replace('&', '%26');
    }
}