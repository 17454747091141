
var DocumentationTranslationMixin = {
    computed: {
        documentationOptionLabels() {
            return {
                title: '',
                disconnect: this.$t("navbar.dropdown.henry.list.logout"),
                connectToDropbox: this.$t('general.connectToDropbox'),
                connectToGoogleDrive: this.$t('general.connectToGoogleDrive'),
                dropbox: {
                    modalTitle: this.$t('general.dropboxFiles'),
                    errorCheckSpace: this.$t('general.errorDropboxCheckSpace')
                },
                google: {
                    modalTitle: this.$t('general.googleDriveFiles'),
                    errorCheckSpace: this.$t('general.errorGoogleDriveCheckSpace')
                },
                upload: this.$t('general.upload'),
                clickHere: this.$t('general.clickHere'),
                dragAndDropFilesHere: this.$t('general.dragAndDropFilesHere'),
                toSelectFiles: this.$t('general.toSelectFiles'),
                fileName: this.$t('general.fileName'),
                size: this.$t('general.size'),
                close: this.$t('general.close'),
                delete: this.$t('general.delete'),
                name: this.$t('general.name'),
                mb: this.$t('general.mb'),
                download: this.$t('general.download'),
                sharedLink: this.$t('general.sharedLink'),
                linkCopiedToClipboard: this.$t('general.linkCopiedToClipboard')
            };
        }
    }
}

export default DocumentationTranslationMixin